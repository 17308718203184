<template>
	<div id="major" ref="major">
		<div class="nav">
			<top-vue></top-vue>
		</div>
		<div class="tab">
			<el-tabs v-model="activeName" :active="active" v-loading="itemLoading" :before-leave="disabledChange">
				<el-tab-pane label="你感兴趣的活动" name="0">
					<div class="check">
						<el-checkbox-group v-model="checkList[0]">
							<el-checkbox v-for="item in list[0]" :key="item.id" :label="item.id">
								{{ item.answerTitle }}
							</el-checkbox>
						</el-checkbox-group>
					</div>
					<div style="width: 100px;height: 100px;margin: 0 auto;">
						<el-button @click="nextClick(1)"
							style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
							下一步</el-button>
					</div>
				</el-tab-pane>
				<el-tab-pane label="你所擅长或胜任的活动" name="1">
					<div class="check">
						<el-checkbox-group v-model="checkList[1]">
							<el-checkbox v-for="item in list[1]" :key="item.id" :label="item.id">
								{{ item.answerTitle }}
							</el-checkbox>
						</el-checkbox-group>
					</div>
					<div style="width: 100px;height: 100px;margin: 0 auto;">
						<el-button @click="nextClick(2)"
							style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
							下一步</el-button>
					</div>
				</el-tab-pane>
				<el-tab-pane label="你所喜欢的职业" name="2">
					<div class="check">
						<el-checkbox-group v-model="checkList[2]">
							<el-checkbox v-for="item in list[2]" :key="item.id" :label="item.id">
								{{ item.answerTitle }}
							</el-checkbox>
						</el-checkbox-group>
					</div>
					<div style="width: 100px;height: 100px;margin: 0 auto;">
						<el-button @click="nextClick(3)"
							style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
							下一步</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import {
	mapState,
} from "vuex"
import topVue from '@/components/top.vue';
import * as systemApi from "@/api/subjectSystemApi"
export default {
	data() {
		return {
			active: "0",
			activeName: '0',
			checkList: [
				[],
				[],
				[],
			],
			list: [
				[],
				[],
				[],
			],
			itemLoading: true,
			nextClickStatus: false,
		}
	},
	components: {
		topVue
	},
	created() {
		this.getStatus();
	},
	computed: {
		...mapState(["majorStatus", "subjectData"]),
	},
	methods: {
		disabledChange() {
			console.log(this.nextClickStatus);
			return this.nextClickStatus;
		},
		getStatus() {
			//console.log(r.syS_SubjectTestStatus);
			console.log("majorStatus:" + this.majorStatus);
			if (this.majorStatus == '') {
				let status = sessionStorage.getItem('majorStatus');
				this.$store.commit('setMajorStatus', status);
			}
			if (Object.keys(this.subjectData).length == 0) {
				let subjectData = JSON.parse(sessionStorage.getItem('subjectData'));
				console.log(subjectData);
				this.$store.commit('setSubjectData', subjectData);
			}
			if (this.majorStatus == '0') {
				this.$router.push('introduce');
				// } else 														
			} else if (this.majorStatus == 4) {
				this.$router.push('character');
			} else if (this.majorStatus == 5) {
				this.$router.push('preliminary');
			} else if (this.majorStatus == 6) {
				this.$router.push('supplement');
			} else if (this.majorStatus == 7) {
				this.$router.push('final');
			} else {
				this.getList();
				if (this.majorStatus == 1) {
					console.log("majorStatus1:" + this.majorStatus);
					this.activeName = '0';
				} else if (this.majorStatus == 2) {
					this.activeName = '1';
				} else if (this.majorStatus == 3) {
					this.activeName = '2';
				}
			}

			// console.log('this.activeName = '+ this.majorStatus +';');
			// this.activeName = '0';

		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		nextClick(type) {
			this.nextClickStatus = true;
			let MemberAddEntity = {
				subjectTestItemIds: this.checkList[type - 1].join(','),
				SYS_TestQuestion: type,
				subjectTestId: this.subjectData.id
			}
			console.log(MemberAddEntity);
			systemApi.subjectNextAdd(MemberAddEntity).then(res => {
				console.log(res);
				this.$nextTick(() => {
					this.$refs.major.scrollIntoView();
				});
				if (res.code == 200) {
					if (type == 3) {
						this.$store.commit('setMajorStatus', '4');
						this.$router.push({
							path: "character"
						})
					}
					this.activeName = type + '';
					setTimeout(() => {
						this.nextClickStatus = false;
					}, 100);
				} else {
					this.$message({
						message: res.message,
						type: 'error'
					})
				}
			})

			// this.activeName = type;
		},
		getList() {
			for (let i = 1; i <= 3; i++) {
				let listQuery = {
					SYS_TestQuestion: i,
					limit: 999
				};
				setTimeout(() => {
					systemApi.getTestItems(listQuery).then(res => {
						//console.log(res);
						// 使用splice刷新数据
						this.list.splice(i - 1, 1, res.data);
						this.itemLoading = false;
						//console.log(this.list);
					})
				}, 100)

			}
		},
	}
}
</script>

<style lang="scss">
#major {
	.tab {
		width: 1200px;
		height: 1614px;
		margin: 40px auto;
		background: #fff;
	}

	.el-checkbox__inner {
		border: 1px solid #000000 !important;
	}
	.el-tabs__item {
		font-weight: bold !important;
	}
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
#major {
	overflow-y: auto;

	.el-tabs__nav {
		background: url(../../assets/dh_bg.png) no-repeat 34px -3px;
	}

	.el-tabs__nav-scroll {
		background: #9eacfd;
		border-radius: 5px;
		padding: 5px 10px;
	}

	.el-tabs__item {
		padding: 0 40px;
		height: 60px;
		line-height: 56px;
		font-size: 20px;
	}

	.el-tabs__item.is-active {
		color: #ffffff;
	}

	.el-tabs__item:hover {
		color: #fff;
	}

	.el-tabs__active-bar {
		background-color: transparent;
	}


	.el-button {
		background: #9eacfd;
	}

	.el-button:focus,
	.el-button:hover {
		background: #9eacfd !important;
	}

	.el-checkbox {
		width: 28%;
		height: 55px;
		text-align: left;
		color: #000000;
	}

	.el-checkbox__label {
		font-size: 18px;
	}
	.check {
		height: 1430px !important;
		margin-bottom: 0 !important;
	}

	.el-checkbox-group {
		text-align: left;
		padding: 20px;
	}

	.check {
		text-align: left;
		padding: 20px;
	}
}
</style>
